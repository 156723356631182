import React, { useEffect, useState } from 'react'
import { useIsDarkMode } from 'state/user/hooks'
import { useWeb3React } from '@web3-react/core'
import Logo from 'assets/app/logo.png'
import { Container, Row, Col, Button, Input } from 'reactstrap'
import { Box } from 'pages/styled'

import { useVestingContract } from 'hooks/useContract'
import { VestingInfo } from 'model/VestingInfo'
import { BigNumber } from 'ethers'
import { useWalletModalToggle } from 'state/application/hooks'
import { convertCurrency } from 'utils/currency'
import { convertDate } from 'utils/date'
import { VESTING } from 'constants/tokens'
import { useActiveWeb3React } from 'hooks/web3'
import { shortenAddress } from 'utils'
import { CheckCircle, Copy } from 'react-feather'
import useCopyClipboard from 'hooks/useCopyClipboard'
import { Trans } from '@lingui/macro'

export default function Vesting() {
  const { chainId } = useActiveWeb3React()
  const darkMode = useIsDarkMode()
  const toggleWalletModal = useWalletModalToggle()
  const { active, account } = useWeb3React()
  const contract = useVestingContract()
  const [claimAmount, setAmount] = useState(BigNumber.from('0'))
  const [add, setAdd] = useState('')

  const [isCopied, setCopied] = useCopyClipboard()
  const [isCopiedContract, setCopiedContract] = useCopyClipboard()
  const [vesting, setVesting] = useState({
    isActive: false,
    amount: BigNumber.from('0'),
    startTimeVesting: BigNumber.from('0'),
    startTimeCliff: BigNumber.from('0'),
    claimedAmount: BigNumber.from('0'),
    cliff: BigNumber.from('0'),
    releaseTotalRounds: BigNumber.from('0'),
    daysPerRound: BigNumber.from('0'),
    tgePercent: BigNumber.from('0'),
    releaseTgeRounds: BigNumber.from('0'),
  })

  const claimVesting = async () => {
    try {
      await contract.claimVestingToken()
      setAmount(BigNumber.from(0))
      setTimeout(function () {
        if (!account) return
        getVestingInfo(account)
      }, 20000)
    } catch (err) {
      console.log(err)
    }
  }
  const getVestingInfo = async (address: string) => {
    if (!address || address == '') return
    try {
      const vest = (await contract.getVestingInfoByUser(address)) as VestingInfo
      vest && setVesting(vest)
    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    try {
      ;(async () => {
        if (!account) return
        const vest = (await contract.getVestingInfoByUser(account)) as VestingInfo
        vest && setVesting(vest)
        const claim = await contract.getVestingClaimableAmount(account)
        claim && setAmount(claim)
      })()
    } catch (err) {
      console.log(err)
    }
  }, [account, contract])
  return (
    <>
      <Container style={{ zIndex: 1 }}>
        <Row>
          <Col style={{ textAlign: 'center' }}>
            <img style={{ width: 156 }} src={darkMode ? Logo : Logo} alt="logo" />
            <h1 className="mt-4">PlanetSandbox Vesting Portal</h1>
          </Col>
        </Row>
        {/* test vesting */}
        <Row>
          <Col style={{ display: 'flex', justifyContent: 'center', marginTop: 50 }}>
            <Input
              placeholder="address wallet"
              style={{ width: '50%', marginRight: 30 }}
              onBlur={(e) => {
                setAdd(e.target.value)
              }}
            ></Input>
            <Button
              onClick={() => {
                getVestingInfo(add)
              }}
            >
              Query
            </Button>
          </Col>
        </Row>

        <Row>
          <Col className="my-5" style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }} xs={12}>
            <Box>
              <h5 className="mb-4 mt-2">Token</h5>
              <div className="item">
                <span className="item-title">Amount</span>
                <span className="item-value" title={convertCurrency(vesting.amount)}>
                  {convertCurrency(vesting.amount)}
                </span>
              </div>
              <div className="item">
                <span className="item-title">TGE</span>
                <span className="item-value" title={convertCurrency(vesting.cliff)}>
                  {vesting.tgePercent.toString() + `%`}
                </span>
              </div>
              <div className="item">
                <span className="item-title">TGE Rounds</span>
                <span className="item-value" title={convertCurrency(vesting.cliff)}>
                  {vesting.releaseTgeRounds.toString()}
                </span>
              </div>
              <div className="item">
                <span className="item-title">Claimed</span>
                <span className="item-value" title={convertCurrency(vesting.claimedAmount)}>
                  {convertCurrency(vesting.claimedAmount)}
                </span>
              </div>
            </Box>
            <Box>
              <h5 className="mb-4 mt-2">Contract</h5>
              <div className="item">
                <span className="item-title">Address</span>
                <span
                  style={{ cursor: 'pointer' }}
                  className="item-value"
                  id="contract"
                  title={chainId ? VESTING[chainId]?.address : ''}
                  onClick={() => setCopied(chainId ? VESTING[chainId]?.address : '')}
                >
                  {isCopied ? (
                    <span>
                      <Trans>Copied</Trans>
                      <CheckCircle className="ms-2" size={'20'} />
                    </span>
                  ) : (
                    <span>
                      {chainId && VESTING[chainId].address && shortenAddress(VESTING[chainId]?.address)}
                      <Copy className="ms-2" size={'20'} />
                    </span>
                  )}
                </span>
              </div>
              <div className="item">
                <span className="item-title">Beneficiary</span>
                <span
                  style={{ cursor: 'pointer' }}
                  className="item-value"
                  title={account ? account : ''}
                  onClick={() => setCopiedContract(account ? account : '')}
                >
                  {isCopiedContract ? (
                    <span>
                      <Trans>Copied</Trans>
                      <CheckCircle className="ms-2" size={'20'} />
                    </span>
                  ) : (
                    <span>
                      {account ? shortenAddress(account) : ''}
                      <Copy className="ms-2" size={'20'} />
                    </span>
                  )}
                </span>
              </div>
            </Box>
            <Box>
              <h5 className="mb-4 mt-2">Vesting Time </h5>
              <div className="item">
                <span className="item-title">Start</span>
                <span className="item-value">{convertDate(vesting.startTimeVesting)}</span>
              </div>
              <div className="item">
                <span className="item-title">Cliff</span>
                <span className="item-value">{vesting.cliff.toString() + ` Days`}</span>
              </div>
              <div className="item">
                <span className="item-title">Days Per Round</span>
                <span className="item-value">{vesting.daysPerRound.toString()}</span>
              </div>
              <div className="item">
                <span className="item-title">Total Rounds</span>
                <span className="item-value">{vesting.releaseTotalRounds.toString()}</span>
              </div>
            </Box>
          </Col>
        </Row>
        <Row>
          <Button
            disabled={active && claimAmount.toString() == '0' ? true : false}
            style={{
              fontWeight: 500,
              fontSize: 24,
              width: '30%',
              margin: '30px auto',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              borderRadius: 0,
              color: 'black',
              background: active && claimAmount.toString() == '0' ? '#ccc' : '#FFD700',
            }}
            onClick={
              !active
                ? toggleWalletModal
                : claimAmount.toString() > '0'
                ? () => {
                    claimVesting()
                  }
                : () => {
                    return
                  }
            }
          >
            {!active
              ? 'Connect Wallet'
              : claimAmount.toString() == '0'
              ? 'Claim'
              : 'Claim ' + convertCurrency(claimAmount)}
          </Button>
        </Row>
      </Container>
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: `linear-gradient(180deg, #1A1A1A 36.89%, rgba(26, 26, 26, 0) 114.31%)`,
        }}
      ></div>
    </>
  )
}
