import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Web3ReactManager from '../components/Web3ReactManager'
import ErrorBoundary from '../components/ErrorBoundary'
import styled from 'styled-components/macro'
import background from '../assets/images/background.png'
import Header from '../components/Header'
import Vesting from './Vesting'

const BodyWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  padding-top: 90px;
  padding-bottom: 30px;
  align-items: center;
  flex: 1;
  background: url(${background});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 0px 15px;
    margin-top: 100px;
    margin-bottom: 70px;
    height: 100vh%;
  `};
`
const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 99;
`

export default function App() {
  return (
    <ErrorBoundary>
      <HeaderWrapper>
        <Header />
      </HeaderWrapper>
      <BodyWrapper>
        <Web3ReactManager>
          <Switch>
            <Route exact strict path="/" component={Vesting} />
          </Switch>
        </Web3ReactManager>
      </BodyWrapper>
    </ErrorBoundary>
  )
}
