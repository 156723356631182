import styled from 'styled-components/macro'
//  define component
export const StandardPageWrapper = styled.div`
  padding-top: 160px;
  width: 100%;
`
export const Box = styled.div`
  width: 31%;
  margin-top: 30px;
  margin-bottom: 30px;
  position: relative;
  border: 1px solid #ffd700;
  min-height: 250px;
  padding: 20px;
  background: ${({ theme }) => theme.bg6};
  ${({ theme }) => theme.mediaWidth.upToSmall`
width:100%;
  `};
  .title {
  }
  .item {
    justify-content: space-between;
    display: flex;
    border: 1px solid white;
    margin-top: 15px;
    .item-title {
      color: black;
      background: #ffd700;
      width: 41%;
      padding: 5px 10px;
    }
    .item-value {
      padding: 5px 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 50%;
      white-space: nowrap;
      text-align: right;
    }
  }
  &:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 10px;
    left: -10px;
    border: 1px solid #ffd700;
    z-index: -1;
    background: ${({ theme }) => theme.bg6};
  }
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 20px;
    left: -20px;
    border: 1px solid #ffd700;
    z-index: -1;
    background: ${({ theme }) => theme.bg6};
  }
`
